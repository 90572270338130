import axiosRequest from "@/AuthenticatedRoute";
import {
  SET_CURRENT_MONDAY_TASK,
  RESET_NEW_MONDAY_TASK,
  UPDATE_NEW_MONDAY_TASK,
  SHOW_MONDAY_DETAILS,
  SET_TASK_COMMENTS,
  TOOGLE_LOADING_COMMENTS,
  SET_TASK_SUBCOMMENTS,
  TOOGLE_LOADING_SUBCOMMENTS,
  SET_MONDAY_TASKS_LOADING,
  SET_TEAM_MONDAY_TASKS,
  SET_PROJECT_MONDAY_TASKS,
  SET_USER_MONDAY_TASKS,
  SET_DAILY_MONDAY_TASKS,
} from "../mutations-types";
import { isDefined } from "@/utils";
import { TaskPriority, TaskStatus, MondayType } from "@/enum";
import {
  filterTasks,
  filterTasksByUser,
  filterTaskByManyParams,
} from "../../utils";

const namespaced = true;

const state = {
  showTeamFilter: false,
  showListOfStatus: false,
  showListOfPriority: false,
  showListOfPerson: false,
  showContextMenu: false,
  showAddListOfPerson: false,
  showTopbarMenus: false,
  filterDropdowns: null,
  dropdownIsClosable: true,
  showMondayOverlay: false,
  currentMondayTask: null,
  mondayType: MondayType.USER_TASK,
  subComments: [],
  comments: [],
  isFirstLoad: true,
  isLoadingSubComments: false,
  isLoadingComments: false,
  isLoadingTasks: false,
  isDeletingTask: null,
  newMondayTask: {
    taskId: null,
    title: null,
    responsable: null,
    type: null,
    workload: null,
    summary: null,
    group: null,
    status: TaskStatus.NOT_STARTED,
    priority: TaskPriority.LOW,
    deadline: null,
    scheduledDate: null,
  },
  personalTaskGroups: [],
  allPersonalTaskGroups: [],
  teamTaskGroups: [],
  allTeamTaskGroups: [],
  dailyTaskGroups: [],
  allDailyTaskGroups: [],
  projectTaskGroups: [],
  allProjectTaskGroups: [],
  selectedMenu: null,
};

const mutations = {
  closeAllDropdowns(state) {
    if (state.dropdownIsClosable) {
      state.showTeamFilter = false;
      state.showListOfStatus = false;
      state.showListOfPriority = false;
      state.showListOfPerson = false;
      state.showAddListOfPerson = false;
      state.showContextMenu = false;
      state.showTopbarMenus = false;
      state.filterDropdowns = null;
    }
  },
  setShowTeamFilter(state, value) {
    state.showTeamFilter = value;
    state.dropdownIsClosable = false;
    setTimeout(() => {
      state.dropdownIsClosable = true;
    }, 100);
  },
  setFilterDropdowns(state, value) {
    state.filterDropdowns = value;
    state.dropdownIsClosable = false;
    setTimeout(() => {
      state.dropdownIsClosable = true;
    }, 100);
  },
  setShowTopbarMenus(state, value) {
    state.showTopbarMenus = value;
    state.dropdownIsClosable = false;
    setTimeout(() => {
      state.dropdownIsClosable = true;
    }, 100);
  },
  setIsFirstLoad(state, value) {
    state.isFirstLoad = value;
  },
  setIsDeletingTask(state, value) {
    state.isDeletingTask = value;
  },
  setShowListOfStatus(state, value) {
    state.showListOfStatus = value;
    state.dropdownIsClosable = false;
    setTimeout(() => {
      state.dropdownIsClosable = true;
    }, 100);
  },
  setShowListOfPriority(state, value) {
    state.showListOfPriority = value;
    state.dropdownIsClosable = false;
    setTimeout(() => {
      state.dropdownIsClosable = true;
    }, 100);
  },
  setShowAddListOfPerson(state, value) {
    state.showAddListOfPerson = value;
    state.dropdownIsClosable = false;
    setTimeout(() => {
      state.dropdownIsClosable = true;
    }, 100);
  },
  setShowListOfPerson(state, value) {
    state.showListOfPerson = value;
    state.dropdownIsClosable = false;
    setTimeout(() => {
      state.dropdownIsClosable = true;
    }, 100);
  },
  setShowContextMenu(state, value) {
    state.showContextMenu = value;
    state.dropdownIsClosable = false;
    setTimeout(() => {
      state.dropdownIsClosable = true;
    }, 100);
  },
  setMondayType(state, value) {
    state.mondayType = value;
  },
  setAllPersonalTaskGroups(state, value) {
    state.allPersonalTaskGroups = value;
  },
  setAllTeamTaskGroups(state, value) {
    state.allTeamTaskGroups = value;
  },
  setAllDailyTaskGroups(state, value) {
    state.allDailyTaskGroups = value;
  },
  setAllProjectTaskGroups(state, value) {
    state.allProjectTaskGroups = value;
  },
  [SET_MONDAY_TASKS_LOADING](state, value) {
    state.isLoadingTasks = value;
  },
  [SET_TEAM_MONDAY_TASKS](state, tasks) {
    state.teamTaskGroups = tasks;
  },
  [SET_DAILY_MONDAY_TASKS](state, tasks) {
    state.dailyTaskGroups = tasks;
  },
  [SET_PROJECT_MONDAY_TASKS](state, tasks) {
    state.projectTaskGroups = tasks;
  },
  [SET_USER_MONDAY_TASKS](state, tasks) {
    state.personalTaskGroups = tasks;
  },
  [SET_TASK_COMMENTS](state, comments) {
    state.comments = comments;
  },
  [TOOGLE_LOADING_COMMENTS](state, value) {
    state.isLoadingComments = value;
  },
  [SET_TASK_SUBCOMMENTS](state, comments) {
    state.subComments = comments;
  },
  [TOOGLE_LOADING_SUBCOMMENTS](state, value) {
    state.isLoadingSubComments = value;
  },
  [RESET_NEW_MONDAY_TASK](state) {
    state.newMondayTask = {
      title: "",
      responsable: null,
      type: "Mondays",
      summary: "",
      workload: null,
      group: null,
      status: TaskStatus.NOT_STARTED,
      priority: TaskPriority.MEDIUM,
      deadline: null,
      scheduledDate: null,
    };
  },
  [UPDATE_NEW_MONDAY_TASK](state, value) {
    state.newMondayTask = value;
  },
  [SET_CURRENT_MONDAY_TASK](state, task) {
    state.currentMondayTask = task;
  },
  [SHOW_MONDAY_DETAILS](state) {
    state.showMondayOverlay = false;
    if (state.currentMondayTask) {
      state.showMondayOverlay = true;
    }
  },
  updateComment(state, comment) {
    const index = state.comments.findIndex((c) => c.id === comment.id);
    state.comments[index] = comment;
  },
  setSelectedMenu(state, value) {
    state.selectedMenu = value;
  },
};

const getters = {
  fillNewMondayTask: (state) => (data) => {
    let task = state.newMondayTask;
    task.workload = data.workload;
    task.taskId = data.id;
    task.status = data.status;
    task.priority = data.priority;
    task.summary = data.summary;
    task.title = data.title;
    task.type = data.type;

    if (data.responsable != null) {
      task.responsable = data.responsable.id;
    }

    return task;
  },
};

const actions = {
  // MONDAY SECTION
  setCurrentMondayTask({ commit }, task) {
    commit(SET_CURRENT_MONDAY_TASK, task);
  },
  showMondayDetails({ dispatch, commit }) {
    commit(SHOW_MONDAY_DETAILS);
    dispatch("loadTaskComments");
  },
  hideMondayDetails({ dispatch, commit }) {
    dispatch("resetCurrentMondayTask");
    commit(SHOW_MONDAY_DETAILS);
  },
  resetCurrentMondayTask({ commit }) {
    commit(SET_CURRENT_MONDAY_TASK, null);
  },
  updateNewMondayTask({ commit }, value) {
    commit(UPDATE_NEW_MONDAY_TASK, value);
  },
  loadTodoTaskGroup({ dispatch, state }, id) {
    switch (state.mondayType) {
      case MondayType.PROJECT_TASK:
        dispatch("loadProjectTodoTaskGroup", id);
        break;
      case MondayType.TEAM_TASK:
        dispatch("loadTeamTodoTaskGroup", id);
        break;
      case MondayType.DAILY_TASK:
        dispatch("loadDailyTodoTaskGroup", id);
        break;
      default:
        dispatch("loadUserTodoTaskGroup", id);
        break;
    }
  },

  // Filter Section
  filterByValue({ commit, state }, value) {
    let data = null;
    switch (state.mondayType) {
      case MondayType.PROJECT_TASK:
        data = JSON.parse(JSON.stringify(state.allProjectTaskGroups));
        commit(SET_PROJECT_MONDAY_TASKS, filterTasks(data, value));
        break;
      case MondayType.TEAM_TASK:
        data = JSON.parse(JSON.stringify(state.allTeamTaskGroups));
        commit(SET_TEAM_MONDAY_TASKS, filterTasks(data, value));
        break;
      default:
        data = JSON.parse(JSON.stringify(state.allPersonalTaskGroups));
        commit(SET_USER_MONDAY_TASKS, filterTasks(data, value));
        break;
    }
  },

  filterByManyParams({ commit, state }, { params }) {
    let data = null;
    switch (state.mondayType) {
      case MondayType.PROJECT_TASK:
        data = JSON.parse(JSON.stringify(state.allProjectTaskGroups));
        commit(SET_PROJECT_MONDAY_TASKS, filterTaskByManyParams(data, params));
        break;
      case MondayType.TEAM_TASK:
        data = JSON.parse(JSON.stringify(state.allTeamTaskGroups));
        commit(SET_TEAM_MONDAY_TASKS, filterTaskByManyParams(data, params));
        break;
      case MondayType.DAILY_TASK:
        data = JSON.parse(JSON.stringify(state.allDailyTaskGroups));
        commit(SET_DAILY_MONDAY_TASKS, filterTaskByManyParams(data, params));
        break;
      default:
        data = JSON.parse(JSON.stringify(state.allPersonalTaskGroups));
        commit(SET_USER_MONDAY_TASKS, filterTaskByManyParams(data, params));
        break;
    }
  },

  filterByUser({ commit, state }, users) {
    const usersList = users.map((user) => user.id);
    let data = null;
    switch (state.mondayType) {
      case MondayType.PROJECT_TASK:
        data = JSON.parse(JSON.stringify(state.allProjectTaskGroups));
        commit(SET_PROJECT_MONDAY_TASKS, filterTasksByUser(data, usersList));
        break;
      case MondayType.TEAM_TASK:
        data = JSON.parse(JSON.stringify(state.allTeamTaskGroups));
        commit(SET_TEAM_MONDAY_TASKS, filterTasksByUser(data, usersList));
        break;
      case MondayType.DAILY_TASK:
        data = JSON.parse(JSON.stringify(state.allDailyTaskGroups));
        commit(SET_DAILY_MONDAY_TASKS, filterTasksByUser(data, usersList));
        break;
      default:
        data = JSON.parse(JSON.stringify(state.allPersonalTaskGroups));
        commit(SET_USER_MONDAY_TASKS, filterTasksByUser(data, usersList));
        break;
    }
  },
  // End filter section

  // Start sorting section
  sortByPriority({ commit }) {
    let data = null;
    switch (state.mondayType) {
      case MondayType.PROJECT_TASK:
        data = JSON.parse(JSON.stringify(state.allProjectTaskGroups));

        commit(SET_PROJECT_MONDAY_TASKS, data);
        break;
      case MondayType.TEAM_TASK:
        data = JSON.parse(JSON.stringify(state.allTeamTaskGroups));
        commit(SET_TEAM_MONDAY_TASKS, data);
        break;
      case MondayType.DAILY_TASK:
        data = JSON.parse(JSON.stringify(state.allDailyTaskGroups));
        commit(SET_DAILY_MONDAY_TASKS, data);
        break;
      default:
        data = JSON.parse(JSON.stringify(state.allPersonalTaskGroups));
        commit(SET_USER_MONDAY_TASKS, data);
        break;
    }
  },

  sortByStatus({ commit }) {
    let data = null;
    switch (state.mondayType) {
      case MondayType.PROJECT_TASK:
        data = JSON.parse(JSON.stringify(state.allProjectTaskGroups));
        commit(SET_PROJECT_MONDAY_TASKS, data);
        break;
      case MondayType.TEAM_TASK:
        data = JSON.parse(JSON.stringify(state.allTeamTaskGroups));
        commit(SET_TEAM_MONDAY_TASKS, data);
        break;
      case MondayType.DAILY_TASK:
        data = JSON.parse(JSON.stringify(state.allDailyTaskGroups));
        data.map((taskGroup) => {
          taskGroup.todoTasks.map((task) => {
            task.status.sort((a, b) => a - b);
          });

          return taskGroup;
        });
        commit(SET_DAILY_MONDAY_TASKS, data);
        break;
      default:
        data = JSON.parse(JSON.stringify(state.allPersonalTaskGroups));
        commit(SET_USER_MONDAY_TASKS, data);
        break;
    }
  },
  // End sorting section

  async loadUserTodoTaskGroup({ commit }, id) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "GET",
        url: "/todotaskGroup/user/" + id,
      })
        .then((response) => {
          const data = response.data.data;
          if (isDefined(data)) {
            commit("setAllPersonalTaskGroups", data);
            commit(SET_USER_MONDAY_TASKS, data);
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async loadProjectTodoTaskGroup({ commit }, id) {
    commit(SET_MONDAY_TASKS_LOADING, true);
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "GET",
        url: "/todotaskGroup/project/" + id,
      })
        .then((response) => {
          const data = response.data.data;
          if (isDefined(data)) {
            commit("setAllProjectTaskGroups", data);
            commit(SET_PROJECT_MONDAY_TASKS, data);
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit(SET_MONDAY_TASKS_LOADING, false);
          commit("setIsFirstLoad", false);
        });
    });
  },

  async loadDailyTodoTaskGroup({ commit }, id) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "POST",
        url: "/todotaskGroup/daily/" + id,
        data: {
          page: 1,
        },
      })
        .then((response) => {
          const data = response.data.data;
          if (isDefined(data)) {
            commit("setAllDailyTaskGroups", data);
            commit(SET_DAILY_MONDAY_TASKS, data);
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async loadTeamTodoTaskGroup({ commit }, id) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "GET",
        url: "/todotaskGroup/team/" + id,
      })
        .then((response) => {
          const data = response.data.data;
          if (isDefined(data)) {
            commit("setAllTeamTaskGroups", data);
            commit(SET_TEAM_MONDAY_TASKS, data);
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async setShowContextMenu({ commit }, value) {
    commit("closeAllDropdowns");
    commit("setShowContextMenu", value);
  },
  async setShowTopbarMenus({ commit }, value) {
    commit("closeAllDropdowns");
    commit("setShowTopbarMenus", value);
  },
  async setShowListOfPerson({ commit }, value) {
    commit("closeAllDropdowns");
    commit("setShowListOfPerson", value);
  },
  async setShowAddListOfPerson({ commit }, value) {
    commit("closeAllDropdowns");
    commit("setShowAddListOfPerson", value);
  },
  async setShowListOfPriority({ commit }, value) {
    commit("closeAllDropdowns");
    commit("setShowListOfPriority", value);
  },
  async setShowListOfStatus({ commit }, value) {
    commit("closeAllDropdowns");
    commit("setShowListOfStatus", value);
  },
  async setShowTeamFilter({ commit }, value) {
    commit("closeAllDropdowns");
    commit("setShowTeamFilter", value);
  },
  async setFilterDropdowns({ commit }, value) {
    commit("closeAllDropdowns");
    commit("setFilterDropdowns", value);
  },
  async createTodoTaskGroup({ commit }, task) {
    commit(SET_MONDAY_TASKS_LOADING, true);
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "POST",
        url: "/todotaskGroup/create",
        data: task,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit(SET_MONDAY_TASKS_LOADING, false);
        });
    });
  },
  resetNewMondayTask({ commit }) {
    commit(RESET_NEW_MONDAY_TASK);
  },

  // eslint-disable-next-line
  async updateTodoTaskGroup({}, task) {
    const id = task.id;
    delete task.id;
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "PUT",
        url: "/todotaskGroup/update/" + id,
        data: task,
      })
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async deleteTodoTaskGroup({ commit }, id) {
    commit("setIsDeletingTask", id);
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "DELETE",
        url: "/todotaskGroup/delete/" + id,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("setIsDeletingTask", null);
        });
    });
  },

  // END MONDAY SECTION

  // TASK COMMENTS SECTIONS
  async loadTaskComments({ commit, state }) {
    commit(TOOGLE_LOADING_COMMENTS, true);
    commit(SET_TASK_COMMENTS, []);
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "GET",
        url: "/todotask/" + state.currentMondayTask?.id + "/comments",
      })
        .then((response) => {
          const data = response.data.data;
          if (isDefined(data)) {
            commit(SET_TASK_COMMENTS, data);
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit(TOOGLE_LOADING_COMMENTS, false);
        });
    });
  },

  async loadSubComments({ commit }, id) {
    commit(TOOGLE_LOADING_SUBCOMMENTS, true);
    commit(SET_TASK_SUBCOMMENTS, []);
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "GET",
        url: "/todotaskcomment/" + id + "/subcomments",
      })
        .then((response) => {
          const data = response.data.data;
          if (isDefined(data)) {
            commit(SET_TASK_SUBCOMMENTS, data);
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit(TOOGLE_LOADING_SUBCOMMENTS, false);
        });
    });
  },

  async createTaskComment({ dispatch }, comment) {
    let formData = new FormData();

    formData.append("content", comment);
    formData.append("todoTask", comment.todoTask);
    if (comment.parent) {
      formData.append("parent", comment.parent);
    }

    if (comment.files?.length > 0) {
      for (let i = 0; i < comment.files.length; i++) {
        formData.append("files[]", comment.files[i]);
      }
    }

    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "POST",
        url: "/todotaskcomment/create",
        data: comment,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          dispatch("loadTaskComments");
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // eslint-disable-next-line
  async updateTaskComment({}, data) {
    const id = data.id;
    delete data.id;
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "PUT",
        url: "/todotaskcomment/update/" + id,
        data: data,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async readComment({ commit }, id) {
    return new Promise((resolve, reject) => {
      axiosRequest({
        method: "GET",
        url: "/todotaskcomment/" + id,
      })
        .then((response) => {
          commit("updateComment", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // END TASK COMMENTS SECTIONS
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
