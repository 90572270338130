export const TaskStatus = Object.freeze({
    PENDING: 'En cours',
    NOT_STARTED: 'En attente',
    VALIDATED: 'Validé',
    CANCELED: 'À refaire',
    TO_APPROVE: 'À contrôler',
    PAUSE: 'En Pause',
});

export const TypeModule = Object.freeze({
    FILE_MODULE: "FILE",
    FOLDER_MODULE: "FOLDER",
    TODO_TASK_MODULE: "TODO_TASK",
    NOTIFICATIONS_MODULE: "NOTIFICATIONS"
});

export const LoadingState = Object.freeze({
    NOT_LOADED: 'not_loaded',
    LOADING: 'loading',
    LOADED: 'loaded',
    ERROR: 'error',
    IDLE: 'idle',
});

export const MondayType = Object.freeze({
    USER_TASK: 'User',
    TEAM_TASK: 'Team',
    DAILY_TASK: 'Daily',
    PROJECT_TASK: 'Project',
})

export const TaskPriority = Object.freeze({
    HIGH: 'Haute',
    MEDIUM: 'Moyenne',
    LOW: 'Basse',
    CRITICAL: 'Critique ⚠️'
});

export const MondayColumns = Object.freeze({
    RESPONSIBLE: 'responsable',
    STATUS: 'status',
    PRIORITY: 'priorité',
});

export const SubscriptionPlan = Object.freeze({
    STARTER_AVOCAT: 'StarterAvocat',
    STARTER_CORPORATE: 'StarterCorporate',
    ENTREPRISE_CORPORATE: 'EnterpriseCorporate',
    ENTREPRISE_AVOCAT: 'EnterpriseAvocat'

});